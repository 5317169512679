import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-phone-input-2/lib/bootstrap.css";
import usePagination from "@mui/material/usePagination/usePagination";
import { Fragment } from "react";
import MetaTags from "../../utils/MetaTags";

function FinalWrapper({ children, title, final, metaTitle, url }) {

    const [searchParams] = useSearchParams()
    const params = usePagination()
    const id = searchParams.get('id')
    const location = useLocation()

    return (<>

        <MetaTags title={metaTitle} url={url} />



        <div className="bg-gray pb-5">
            {
                <div className="results-container container-xxl pb-5 d-flex flex-column align-items-center">
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-start justify-content-between flex-lg-row flex-column final__header">
                            <h2 className="py-2 pt-5 mb-0 bold me-5">
                                {title}
                            </h2>
                            { final && 
                            <div className="d-flex flex-column ms-0 ms-lg-5">
                                <p className="py-2 pt-lg-5">Thank you for your rental booking. It has been processed successfully.</p>
                                <Link to={`/voucher?id=${id}`} className="btn btn-primary ms-0 ms-lg-auto mt-4 mt-lg-0 print__btn mt-2" target="_blank" rel="noopener">Print Receipt</Link>
                            </div>
                            }
                        </div>
                    </div>

                    <div className="row flex-wrap-reverse justify-content-center">
                        <div className="col-lg-4">
                            <div className="card card__border p-3 my-4">
                                <div className="card-body">
                                    <h6 className="cars-header-title bold pb-2">
                                        Benefits
                                    </h6>
                                    <div className="semi-bold">
                                        <div className="my-2">
                                            <i className="bi bi-check-lg text-green"></i> Diverse Fleet
                                        </div>
                                        <div className="my-2">
                                            <i className="bi bi-check-lg text-green"></i> Competitive prices
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> Best Insurance Covers
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> Free cancellation
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> 24/7 customer care
                                        </div>
                                        <div className="mt-2">
                                            <i className="bi bi-check-lg text-green"></i> No hidden charges
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card card__border p-3 my-4">
                                <div className="card-body">
                                    <div className="card-body d-flex flex-column">
                                        <h6 className="bold pb-3 mb-3 border-bottom">
                                            Guaranteed Low Price
                                        </h6>
                                        <p className='mb-0'>PRICE START AT</p>
                                        <p className='driver-profile__price--container ms-0 ps-0 mb-0'>
                                            <span className='driver-profile__currency'>€</span>
                                            <span className='driver-profile__price bold'>25</span>
                                            <span className='driver-profile__terms'>/day</span>
                                        </p>
                                        <div className='driver-profile__img d-flex justify-content-center'><img src="../../../images/final_car_photo.jpg" /></div>
                                        <Link className='btn btn-outline-dark mt-3 driver-profile__home-link' to='/'>SEARCH FOR CAR</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {children}
                    </div>
                </div>
            }
        </div>
    </>);
}

export default FinalWrapper;